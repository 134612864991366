import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/vercel/path0/components/accordion/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Paragraph"] */ "/vercel/path0/components/commonComponents/paragraph/paragraph.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Stats"] */ "/vercel/path0/components/commonComponents/stats/stats.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/commonComponents/testimonial/testimonial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactFormSection"] */ "/vercel/path0/components/contact-form-section/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactInfoBlock"] */ "/vercel/path0/components/contact-info-block/contactInfoBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Head"] */ "/vercel/path0/components/internalComponents/head/head.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/apple.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/arrow-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/arrow-north-east.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/checkmark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/chevron-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/clock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter01.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter02.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter03.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter04.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter05.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter06.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter07.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter08.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter09.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/counter10.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/cross.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/email-alt.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/email.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/file.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/github.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/hamburger.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/heart.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/info.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/long-arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/mouse-scroll.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/phone.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/play.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/plus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/podimo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/rank.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/spotify.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/tiktok.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/trash.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/triangle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/upload.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/svgs/zigzag.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pageComponents/section/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pageComponents/container/container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/testimonialGrid/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/commonComponents/heading/heading.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/internalComponents/icon/icon.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/buttons/core/IconPosition.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/buttons/components/button.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout-container/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MultiImageItem"] */ "/vercel/path0/components/multiImage/multiImageItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeHero"] */ "/vercel/path0/components/pageComponents/hero/homeHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPosts"] */ "/vercel/path0/components/selfContainedComponents/blogPosts/blogPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PeopleCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/peopleCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PicturesCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/picturesCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialCarousel"] */ "/vercel/path0/components/selfContainedComponents/carousel/testimonialCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cases"] */ "/vercel/path0/components/selfContainedComponents/cases/cases.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Clients"] */ "/vercel/path0/components/selfContainedComponents/clients/clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactCapi"] */ "/vercel/path0/components/selfContainedComponents/contactCapi/contactCapi.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IllustrationRow"] */ "/vercel/path0/components/selfContainedComponents/illustrationRow/illustrationRow.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/mediaEmbed/mediaEmbed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShowCase"] */ "/vercel/path0/components/selfContainedComponents/showCase/showCase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleList"] */ "/vercel/path0/components/selfContainedComponents/simpleList/simpleList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/textBlock/textBlock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/selfContainedComponents/tileList/tileList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Vacancies"] */ "/vercel/path0/components/selfContainedComponents/vacancies/vacancies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VacancyList"] */ "/vercel/path0/components/selfContainedComponents/vacancyList/vacancyList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseCarousel"] */ "/vercel/path0/components/tileCarousel/caseCarousel/caseCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsCarousel"] */ "/vercel/path0/components/tileCarousel/newsCarousel/newsCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeamCarousel"] */ "/vercel/path0/components/tileCarousel/teamCarousel/teamCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VacancyForm"] */ "/vercel/path0/components/vacancy-form/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/link.js");
